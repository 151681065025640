import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	// These properties are public because they are used in the view
	public appMessage!: string; //self closing message displayed at top of page

	constructor(private authService: AuthService, private router: Router) {}

	ngOnInit(): void {
		this.authService.getAuth().subscribe((auth) => {
			if (auth) {
				this.router.navigate(['/']);
				return;
			}
		});
	}

	onSubmit(): void {
		this.authService
			.login()
			.then(() => {
				this.router.navigate(['/']);
			})
			.catch((error) => {
				console.log(error.message);
			});
	}
}
