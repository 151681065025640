export class CustomFunctions {
	/***********************************************************************************************************************
	 * calculateAge returns a Age from a given date
	 * @param {string} birthday a birthday as a string
	 * @return {number}
	 ***********************************************************************************************************************/
	static calculateAge(birthday: string): number {
		const epochDOB = Date.parse(birthday);
		const dob = new Date(epochDOB);
		const currentDate = new Date();
		return Math.abs(dob.getUTCFullYear() - currentDate.getUTCFullYear());
	}

	/***********************************************************************************************************************
	 * stringify a JSON even if there are circular references
	 * @param {date}
	 * @return {string} YYYY-mm-dd
	 ***********************************************************************************************************************/
	static dateToDateString(d: Date) {
		const yyyy = d.getFullYear().toString();
		const mm = (d.getMonth() + 1).toString();
		const dd = d.getDate().toString();

		const mmChars = mm.split('');
		const ddChars = dd.split('');

		return yyyy + '-' + (mmChars[1] ? mm : '0' + mmChars[0]) + '-' + (ddChars[1] ? dd : '0' + ddChars[0]);
	}

	static timeToDateString(d: Date) {
		const givenDate = new Date(d);

		const hh = givenDate.getHours();
		let dd = 'AM';
		let h = hh;
		if (h >= 12) {
			h = hh - 12;
			dd = 'PM';
		}
		if (h == 0) {
			h = 12;
		}

		const givenHour = h.toString();
		const givenMinutes = givenDate.getMinutes().toString();

		const minutesChars = givenMinutes.split('');

		return `${givenHour}:${minutesChars[1] ? givenMinutes : '0' + givenMinutes[0]} ${dd}`;
	}

	static dateToFriendlyDate(d: Date) {
		const givenDate = new Date(d);

		const hh = givenDate.getHours();
		let ml = 'AM';
		let h = hh;
		if (h >= 12) {
			h = hh - 12;
			ml = 'PM';
		}
		if (h == 0) {
			h = 12;
		}

		const currentMonth = givenDate.toDateString().split(' ')[1];
		const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		const dayName = days[givenDate.getDay()];
		const dd = givenDate.getDate().toString();
		const ddChars = dd.split('');
		const givenHour = h.toString();
		const givenMinutes = givenDate.getMinutes().toString();

		const minutesChars = givenMinutes.split('');

		return `${dayName} ${currentMonth} ${ddChars[1] ? dd : '0' + ddChars[0]} ${givenHour}:${minutesChars[1] ? givenMinutes : '0' + givenMinutes[0]} ${ml}`;
	}

	/***********************************************************************************************************************
	 * formatCalendarControl returns string date for the calendar control
	 * @param {date}
	 * @return {string} mm/dd/YYYY
	 ***********************************************************************************************************************/
	static formatCalendarControl(date: Date) {
		try {
			const d = new Date(date);
			const birthDay = d.getDate().toString().padStart(2, '0');
			const birthMonth = (d.getMonth() + 1).toString().padStart(2, '0');
			const birthYear = d.getFullYear();

			const checkBirthDay = Number(birthDay);
			const checkBirthMonth = Number(birthMonth);
			const checkBirthYear = Number(birthYear);

			if (!checkBirthDay && !checkBirthMonth && !checkBirthYear) throw new Error(`Invalid Date ${date}`);

			return `${birthYear}-${birthMonth}-${birthDay}`;
		} catch (error) {
			console.log('formatCalendarControl', error);
		}
	}

	static formatDateString(dateString: string, mode: 'display' | 'store') {
		try {
			const dateStringTest = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
			if (dateStringTest.test(dateString)) {
				const dateStringYear = dateString.substring(0, 4);
				const dateStringMonth = dateString.substring(5, 7);
				const dateStringDay = dateString.substring(8, 10);
				if (mode === 'store') {
					return `${dateStringYear}-${dateStringMonth}-${dateStringDay}`;
				} else if (mode === 'display') {
					return `${dateStringMonth}/${dateStringDay}/${dateStringYear}`;
				} else {
					throw new Error(`Mode ${mode} does not match 'display' or 'store'`);
				}
			} else {
				throw new Error(`Invalid Date ${dateString}. Required format YYYY-MM-DD`);
			}
		} catch (error) {
			console.log('formatDateString', error);
		}
	}

	static localClientTime(): string {
		const tzoffset = new Date().getTimezoneOffset() * 60000;
		return new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
	}

	/***********************************************************************************************************************
	 * stringify a JSON even if there are circular references
	 * @param {number} daysOffset offset in days
	 * @return {string}
	 ***********************************************************************************************************************/
	static midnight(daysOffset = 0): string {
		const tzoffset = new Date().getTimezoneOffset() * 60000;
		const msOffset = daysOffset * 24 * 60 * 60000;

		const d = new Date(Date.now() - tzoffset + msOffset).toISOString().slice(0, -1);

		const date = new Date(d);

		const dayAsInt = date.setDate(date.getDate());
		const givenDate = new Date(dayAsInt);
		const day = `0${givenDate.getDate()}`;
		const month = `0${givenDate.getMonth() + 1}`;
		const year = `${givenDate.getFullYear()}`;

		const formattedTime = `${year}-${month.substr(-2)}-${day.substr(-2)}T00:00:00`;

		return formattedTime;
	}

	static shuffle(array: []): [] {
		let currentIndex: number = array.length,
			temporaryValue,
			randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	/***********************************************************************************************************************
	 * stringify a JSON even if there are circular references
	 * @param {object}
	 * @return {any}
	 ***********************************************************************************************************************/
	static stringy(object: Record<string, unknown>) {
    
		let cache: unknown[] = [];
    
		const data: string = JSON.stringify(object, (key: string, value: unknown) => {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value as never) !== -1) {
					// Duplicate reference found, discard key
					return;
				}
				// Store value in our collection
				cache.push(value);
			}
			return value;
		});
		cache = [];
		return data;
	}
}

export interface Email {
	sender: string;
	to: string;
	subject: string;
	text: string;
}
