import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MegaMenuItem } from 'primeng/api';
import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
	public isLoggedIn = true;
	public items: MegaMenuItem[] = [];
	constructor(private authService: AuthService, private router: Router) {}

	ngOnInit() {
		this.authService.getAuth().subscribe(auth => {
			if (auth) {
				const userDomain = auth.email?.replace(/.*@/, '');
				if (userDomain !== 'yongsa.net') {
					this.authService.logout();
				} else {
					this.isLoggedIn = true;
					this.setItems();
				}
			} else {
				this.isLoggedIn = false;
				this.router.navigate(['/login']);
			}
		});
	}

	onMenuClick(e: { item: { id: string | undefined } }): void {
		this.items.forEach((item, index) => {
			if (item.id === e.item.id) {
				this.router.navigate([`/${e.item.id}`]);
			}
		});
	}
	onLogoutClick() {
		this.setItems(false);
		this.authService.logout();
		this.router.navigate(['/login']);
	}

	closeItem(event: { preventDefault: () => void }, index: number) {
		this.items = this.items.filter((item, i) => i !== index);
		event.preventDefault();
	}

	setItems(_visibility = true) {
		this.items = [
			{
				id: 'logout',
				label: 'Logout',
				icon: 'pi pi-fw pi-sign-in',
				visible: _visibility,
				command: () => {
					this.onLogoutClick();
				},
			},
		];
	}
}
