import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomFunctions } from 'src/app/models/global.model';
import { firebase } from '../../../environments/environment';
import { Schedule } from '../../models/programs.model';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebase.service';

interface DisplaySchedule extends Schedule {
	startTime: string;
	endTime: string;
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnDestroy {
	// These properties are private because they are only used in the component
	private scheduleSubscription!: Subscription;

	// These properties are public because they are used in the view
	public schedules: any[] = [];

	public memberListFont = '3vh';

	constructor(private firebaseService: FirebaseService, private authService: AuthService) {}

	ngOnInit(): void {
		this.authService.user$.subscribe(() => this.getSchedules());
	}

	getSchedules(): void {
		const tzoffset = new Date().getTimezoneOffset() * 60000;
		const classSize: unknown[] = [];

		const startDate = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);

		const endDate = CustomFunctions.midnight(firebase.offSetDays + 2);

		this.scheduleSubscription = this.firebaseService.getSchedule(startDate, endDate).subscribe(values => {
			this.refreshSchedule(values[0].end.dateTime);
			this.schedules = values;
		});
	}

	refreshSchedule(time: string | number | Date): void {
		const futureTime = new Date(time).getTime();
		const currentTime = new Date().getTime();
		setTimeout(() => {
			this.getSchedules();
		}, futureTime - currentTime);
	}

	ngOnDestroy(): void {
		if (this.scheduleSubscription) this.scheduleSubscription.unsubscribe();
	}
}
