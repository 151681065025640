import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MegaMenuModule } from 'primeng/megamenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { firebase } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { LoginComponent } from '../components/login/login.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { AppRoutingModule } from '../routing/app.routing';
import { AuthService } from '../services/auth.service';
@NgModule({
	declarations: [AppComponent, DashboardComponent, LoginComponent, NavbarComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		ButtonModule,
		CardModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		MegaMenuModule,
		ReactiveFormsModule,
		ToggleButtonModule,
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
	],
	providers: [AuthService],
	bootstrap: [AppComponent],
})
export class AppModule {}
