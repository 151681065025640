import { Injectable } from '@angular/core';
import { collection, collectionChanges, CollectionReference, Firestore, limit, orderBy, query, where, collectionSnapshots } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { CustomFunctions } from '../models/global.model';
import { Schedule } from '../models/programs.model';

interface DisplaySchedule extends Schedule {
	startTime: string;
	endTime: string;
}

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	constructor(private afs: Firestore) {}

	getSchedule(startDate: string, endDate: string) {
		return collectionSnapshots<DisplaySchedule>(
			query<DisplaySchedule>(
				collection(this.afs, 'mas-schedules') as CollectionReference<DisplaySchedule>,
				where('end.dateTime', '>', startDate),
				where('end.dateTime', '<', endDate),
				orderBy('end.dateTime'),
				limit(3)
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.startTime = CustomFunctions.timeToDateString(data.start.dateTime as unknown as Date);
					data.endTime = CustomFunctions.timeToDateString(data.end.dateTime as unknown as Date);
					data.id = a.id;
					return data;
				});
			})
		);
	}
}
